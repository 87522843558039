<template>
  <div v-if="loaded" class="flex-container non-scrollable">
    <page-title value="Portés"/>
    <tableau-porte :missions="missions" :fields="fields" is-deletable enableArchivedMissionSelector
                   @reload="initMissions"/>
  </div>
</template>

<script>
import TableauPorte from '../../../components/TableauPorte'
import { mapGetters } from 'vuex'
import PageTitle from "@/components/PageTitle";

export default {
  name: 'CommercialPortes',
  components: {PageTitle, TableauPorte },
  data () {
    return {
      loaded: false,
      fields: [
          'indepLastName',
          'indepFirstName',
          'clientContract',
          'clientFinal',
          'mission',
          'endContract',
          'renewal',
          'contractType',
          'status',
      ]
    }
  },
  computed: {
    ...mapGetters({
      missions: 'mission/getMissions'
    })
  },
  async created () {
    this.loaded = false
    await this.initMissions()
    this.loaded = true
  },
  methods: {
    async initMissions () {
      await this.$store.dispatch('mission/getAllMissionsForEmployees')
    }
  }
}
</script>

<style scoped>

</style>
